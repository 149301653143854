import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Platform, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { Redirect, RouteComponentProps, useHistory } from "react-router";
import APP_PATHS from "../config/appPaths";
import ClosePointOfSaleScreen from "../screens/ClosePointOfSaleScreen";
import InvitedScreen from "../screens/InvitedScreen";
import LoginScreen from "../screens/LoginScreen";
import OnboardingScreen from "../screens/OnboardingScreen";
import OpenPointOfSaleScreen from "../screens/OpenPointOfSaleScreen";
import OrganizationSelectScreen from "../screens/OrganiationSelectScreen";
import OrganizationAdminsScreen from "../screens/OrganizationAdminsScreen";
import OrganizationCreateScreen from "../screens/OrganizationCreateScreen";
import OrganizationHomeScreen from "../screens/OrganizationHomeScreen";
import OrganizationPrintMaterialScreen from "../screens/OrganizationPrintMaterialScreen";
import OrganizationReportAvailabilityScreen from "../screens/OrganizationReportAvailabilityScreen";
import OrganizationReportRecieversScreen from "../screens/OrganizationReportRecieversScreen";
import OrganizationReportsScreen from "../screens/OrganizationReportsScreen";
import OrganizationSettingsContactScreen from "../screens/OrganizationSettingsContactScreen";
import OrganizationSettingsDetailsScreen from "../screens/OrganizationSettingsDetailsScreen";
import OrganizationSettingsGroupsScreen from "../screens/OrganizationSettingsGroupsScreen";
import OrganizationSettingsMainScreen from "../screens/OrganizationSettingsMainScreen";
import OrganizationSettingsPaymentMethodsScreen from "../screens/OrganizationSettingsPaymentMethodsScreen";
import OrganizationsSettingsUserManagementScreen from "../screens/OrganizationsSettingsUserManagementScreen";
import OrganizationSuperAdminScreen from "../screens/OrganizationSuperAdminScreen";
import OrganizationUsersScreen from "../screens/OrganizationUsersScreen";
import PointOfSaleHomeScreen from "../screens/PointOfSaleHomeScreen";
import PointOfSaleReportScreen from "../screens/PointOfSaleReportScreen";
import PointOfSaleReportsScreen from "../screens/PointOfSaleReportsScreen";
import PointOfSalesCategoriesScreen from "../screens/PointOfSalesCategoriesScreen";
import PointOfSaleCreateScreen from "../screens/PointOfSalesCreateScreen";
import PointOfSaleSettingsDetailsScreen from "../screens/PointOfSaleSettingsDetailsScreen";
import PointOfSaleSettingsPaymentMethodsScreen from "../screens/PointOfSaleSettingsPaymentMethodsScreen";
import PointOfSaleSettingsScreen from "../screens/PointOfSaleSettingsScreen";
import PointOfSaleItemsScreen from "../screens/PointOfSalesItemsScreen";
import PointOfSaleSelectScreen from "../screens/PointOfSalesSelectScreen";
import ProfileScreen from "../screens/ProfileScreen";
import PurchaseHistoryScreen from "../screens/PurchaseHistoryScreen";
import RegisterScreen from "../screens/RegisterScreen";
import { RootStoreContext } from "../stores/RootStore";
import { Route, Router, Switch } from "./index";

interface Props {
  initialRoute?: string | null;
}

const Routes: React.FC<Props> = observer(({ initialRoute }) => {
  let shouldRedirect = !!initialRoute;
  if (Platform.OS !== "web") shouldRedirect = false;

  const [renders, setRenders] = useState(0);
  const [renderRedirect, setRenderRedirect] = useState(shouldRedirect);

  const { userStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (renderRedirect && initialRoute && renders === 0) {
      setRenderRedirect(false);
      setRenders(renders + 1);
    }
  }, [renders]);

  return (
    <Router initialEntries={[initialRoute ? initialRoute : "/"]}>
      {/* <Router initialEntries={[routerStore.pathname]}> */}
      <RouterWatcher>
        <Switch>
          {/* {renderRedirect && (
            <Route path="/" render={props => <Redirect to={initialRoute} />} />
          )} */}
          {/*
          // ORGANISATION ROUTES
          */}
          <Route
            exact
            path={APP_PATHS.onboarding.profile}
            render={(props) => <ProfileScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.select}
            render={(props) => <OrganizationSelectScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.home}
            render={(props) => <OrganizationHomeScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.settings.home}
            render={(props) => <OrganizationSettingsMainScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.settings.printmaterial}
            render={(props) => <OrganizationPrintMaterialScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.settings.groups}
            render={(props) => <OrganizationSettingsGroupsScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.settings.usermanagement}
            render={(props) => (
              <OrganizationsSettingsUserManagementScreen {...props} />
            )}
          />
          <Route
            exact
            path={APP_PATHS.organization.settings.details}
            render={(props) => <OrganizationSettingsDetailsScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.settings.reciept}
            render={(props) => <OrganizationSettingsContactScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.settings.reportavailability}
            render={(props) => (
              <OrganizationReportAvailabilityScreen {...props} />
            )}
          />
          <Route
            exact
            path={APP_PATHS.organization.settings.reportrecievers}
            render={(props) => <OrganizationReportRecieversScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.settings.paymentmethods}
            render={(props) => (
              <OrganizationSettingsPaymentMethodsScreen {...props} />
            )}
          />
          <Route
            exact
            path={APP_PATHS.organization.create}
            render={(props) => <OrganizationCreateScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.reports}
            render={(props) => <OrganizationReportsScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.users}
            render={(props) => <OrganizationUsersScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.admins}
            render={(props) => <OrganizationAdminsScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.organization.superadmins}
            render={(props) => <OrganizationSuperAdminScreen {...props} />}
          />
          {/*
            // POINT OF SALE ROUTES
          */}
          <Route
            exact
            path={APP_PATHS.pointofsale.select}
            render={(props) => <PointOfSaleSelectScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.pointofsale.home}
            render={(props) => <PointOfSaleHomeScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.pointofsale.items}
            render={(props) => <PointOfSaleItemsScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.pointofsale.categories}
            render={(props) => <PointOfSalesCategoriesScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.pointofsale.settings.home}
            render={(props) => <PointOfSaleSettingsScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.pointofsale.settings.details}
            render={(props) => <PointOfSaleSettingsDetailsScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.pointofsale.settings.paymentmethods}
            render={(props) => (
              <PointOfSaleSettingsPaymentMethodsScreen {...props} />
            )}
          />
          <Route
            exact
            path={APP_PATHS.pointofsale.create}
            render={(props) => <PointOfSaleCreateScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.pointofsale.reports}
            render={(props) => <PointOfSaleReportsScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.pointofsale.report}
            render={(props) => <PointOfSaleReportScreen {...props} />}
          />
          {/*
              // Register
            */}
          <Route
            exact
            path={APP_PATHS.register.register}
            render={(props) => <RegisterScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.register.open}
            render={(props) => <OpenPointOfSaleScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.register.close}
            render={(props) => <ClosePointOfSaleScreen {...props} />}
          />
          <Route
            exact
            path={APP_PATHS.register.history}
            render={(props) => <PurchaseHistoryScreen {...props} />}
          />

          {/*
              // Other
            */}
          <Route
            exact
            path={APP_PATHS.onboarding.onboarding}
            component={OnboardingScreen}
          />
          <Route
            exact
            path={APP_PATHS.onboarding.login}
            component={LoginScreen}
          />
          <Route
            exact
            path={APP_PATHS.onboarding.about}
            component={HelpScreen}
          />
          <Route
            path={`${APP_PATHS.onboarding.invited}/:orgid`}
            // path={`${APP_PATHS.onboarding.invited}/:orgId`}
            component={InvitedScreen}
          />

          {/* REDIRECT */}
          <Route
            path="/"
            render={(props) => (
              <Redirect to={APP_PATHS.onboarding.onboarding} />
            )}
          />
        </Switch>
      </RouterWatcher>
    </Router>
  );
});

const RouterWatcher: React.FC = observer(({ children }) => {
  const history = useHistory();
  console.log(history);
  const { location } = history;
  const { routerStore } = useContext(RootStoreContext);
  let prevLocation = Object.assign({}, location);

  useEffect(() => {
    if (prevLocation !== location) {
      // console.log("ROUTE CHANGE!");
      routerStore.pathname = location.pathname;
    }
    // console.log(location);
  }, [location]);
  return <>{children}</>;
});
export default Routes;

interface HelpScreenProps extends RouteComponentProps {}
const HelpScreen: React.FC<HelpScreenProps> = ({ history }) => (
  <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
    <Text h3>Help!</Text>
    <Button
      title="Home"
      onPress={() => history.push(APP_PATHS.organization.select)}
    />
  </View>
);
