import APP_PATHS from "./appPaths";
console.log(process.env);
// const API_ULRS = {
//   app: process.env.API_URL_APP || "https://app2-staging.teampay.se",
//   url: process.env.API_URL_URL || "https://backend-stage.teampay.se",
//   link: process.env.API_URL_LINK || "https://backend-stage.teampay.se",
//   authlink:
//     process.env.API_URL_AUTHLINK ||
//     "https://tp-apiproxy.dev.teampay.se/backend",
//   authbase:
//     process.env.API_URL_AUTHBASE || "https://tp-apiproxy.dev.teampay.se",
//   proxybase:
//     process.env.API_URL_PROXYBASE || "https://tp-apiproxy.dev.teampay.se"
// };
const API_ULRS = {
  app: process.env.API_URL_APP || "https://app2.teampay.se",
  url: process.env.API_URL_URL || "https://backend.teampay.se",
  link: process.env.API_URL_LINK || "https://backend.teampay.se",
  authlink:
    process.env.API_URL_AUTHLINK ||
    "https://tp-apiproxy.dev.teampay.se/backend",
  authbase:
    process.env.API_URL_AUTHBASE || "https://tp-apiproxy.dev.teampay.se",
  proxybase:
    process.env.API_URL_PROXYBASE || "https://tp-apiproxy.dev.teampay.se"
};

const DEBUG_PRIVILEGES = false;
const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
export { APP_PATHS, API_ULRS, DEBUG_PRIVILEGES, EMPTY_GUID };
