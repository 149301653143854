import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Image,
  Picker,
  Platform,
  ScrollView,
  Switch,
  TouchableOpacity,
  View,
} from "react-native";
import CalendarPicker from "react-native-calendar-picker";
import { Button, Text } from "react-native-elements";
import { Row, Rows, Table } from "react-native-table-component";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import Icon from "../components/Icon/Icon";
import { ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import { API_ULRS, APP_PATHS } from "../config";
import axiosInstance from "../lib/axios";
import { theme } from "../lib/styles/theme";
import { RootStoreContext } from "../stores/RootStore";

// registerLocale("sv", sv);

interface Props extends RouteComponentProps {}

const OrganizationReportsScreen: React.FC<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [timeFilter, setTimeFilter] = useState<String>("week");
  const [beginDate, setBeginDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));
  const [reportData, setReportData] = useState<any>();
  const [tableHead, setTableHead] = useState<any>();
  const [tableData, setTableData] = useState<any>();
  const { organizationStore } = useContext(RootStoreContext);
  const [showStatsFilter, setShowStatsFilter] = useState(false);
  const [groupFilter, setGroupFilter] = useState([]);
  const [posFilter, setPosFilter] = useState([]);
  const [reportType, setReportType] = useState("sales");
  const [displayDateSelector, setDisplayDateSelector] = useState(false);
  const [posFilterText, setPosFilterText] = useState([]);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [showBeingDatePicker, setShowBeingDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);
  useEffect(() => {
    setLoading(true);
    const getReport = async () => {
      const reportUrl = `${API_ULRS.url}/organization/${organizationStore.organizationId}/reports/${reportType}Report`;
      const reportQuery = {
        posId: null,
        posIdList: posFilter || null,
        startDate: beginDate,
        endDate: endDate,
      };

      console.log(reportUrl);
      console.log(reportQuery);
      const { data } = await axiosInstance.post(
        reportUrl,
        JSON.stringify(reportQuery)
      );

      setReportData(data.data);
      setLoading(false);
      console.log(data);
    };
    getReport();
  }, [beginDate, endDate, posFilter, reportType]);

  useEffect(() => {
    if (reportData) {
      const tableDataArray = [];
      console.log("REPORTDATA: ", reportData);
      // if sales report
      if (reportType === "sales") {
        reportData.entries
          .sort((a, b) =>
            new Date(a.firstDate) > new Date(b.firstDate)
              ? -1
              : new Date(b.firstDate) > new Date(a.firstDate)
              ? 1
              : 0
          )
          .map((entry, idx) => {
            tableDataArray.push(["Kassa: " + entry.posName]);
            tableDataArray.push([
              moment(entry.firstDate).format("DD/MM"),

              entry.numberOfReceipts + " st",
              entry.avgSalePerOrder + " kr",
              entry.total + " kr",
            ]);
          });
        setTableHead(["Datum", "Antal köp", "Snitt", "Totalt"]);
      }
      // if items / order
      else {
        // setMaxQuantity(Math.max(reportData.items.)
        reportData.items
          .sort((a, b) =>
            a.priceInclVat > b.priceInclVat
              ? -1
              : b.priceInclVat > a.priceInclVat
              ? 1
              : 0
          )
          .map((entry, idx) => {
            tableDataArray.push([
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Icon name={entry.icon} size={16} />
                <Text style={{ marginLeft: 5 }}>{entry.itemName}</Text>
              </View>,
              <View>
                <Text style={{ textAlign: "center" }}>
                  {entry.quantity + " st"}
                </Text>
              </View>,
              <View>
                <Text style={{ textAlign: "right" }}>
                  {entry.priceInclVat + " kr"}
                </Text>
              </View>,
              // moment(entry.firstDate).format("DD/MM"),
              // entry.numberOfReceipts,
              // entry.avgSalePerOrder,
              // entry.total
            ]);
          });
        setTableHead([
          "Produkt",
          <Text style={{ textAlign: "center", fontWeight: "bold" }}>
            Antal sålda
          </Text>,
          <Text style={{ textAlign: "center", fontWeight: "bold" }}>
            Totalt värde
          </Text>,
        ]);
      }
      setTableData(tableDataArray);
    }
  }, [reportData]);

  const dataRange = (inputFilter) => {
    switch (inputFilter) {
      case "week":
        setBeginDate(moment().startOf("week"));
        setEndDate(moment().endOf("week"));
        // setBeginDate(
        //   moment()
        //     .subtract(7, "d")
        //     .format("YYYY-MM-DD")
        // );
        // setEndDate(
        //   moment()
        //     .add(1, "d")
        //     .format("YYYY-MM-DD")
        // );
        console.log("Week selected");
        break;

      case "lastweek":
        setBeginDate(moment().subtract(1, "week").startOf("week"));
        setEndDate(moment().subtract(1, "week").endOf("week"));
        // setBeginDate(
        //   moment()
        //     .subtract(7, "d")
        //     .format("YYYY-MM-DD")
        // );
        // setEndDate(
        //   moment()
        //     .add(1, "d")
        //     .format("YYYY-MM-DD")
        // );
        break;

      case "month":
        setBeginDate(moment().startOf("month"));
        setEndDate(moment().endOf("month"));
        break;

      case "lastmonth":
        setBeginDate(moment().subtract(1, "month").startOf("month"));
        setEndDate(moment().subtract(1, "month").endOf("month"));
        break;
      case "quarter":
        setBeginDate(moment().startOf("quarter"));
        setEndDate(moment().endOf("quarter"));
        break;
      case "lastquarter":
        setBeginDate(moment().subtract(1, "quarter").startOf("quarter"));
        setEndDate(moment().subtract(1, "quarter").endOf("quarter"));
        break;

      case "year":
        setBeginDate(moment().startOf("year"));
        setEndDate(moment().endOf("year"));
        break;
      case "lastyear":
        setBeginDate(moment().subtract(1, "year").startOf("year"));
        setEndDate(moment().subtract(1, "year").endOf("year"));
        break;

      default:
        break;
    }

    console.log(
      `${inputFilter} selected. Start: ${beginDate}, End: ${endDate}`
    );
  };
  console.log("ReportState");
  console.log(tableData);
  console.log("GroupFilter:", groupFilter);

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            ),
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }

  const toggleGroupFilter = (groupId) => {
    let tmpArray = groupFilter.slice();
    if (tmpArray.includes(groupId)) {
      tmpArray.splice(tmpArray.indexOf(groupId), 1);
    } else {
      tmpArray.push(groupId);
    }
    setGroupFilter(tmpArray);
  };
  const togglePosFilter = (posId) => {
    let tmpArray = posFilter.slice();
    if (tmpArray.includes(posId)) {
      tmpArray.splice(tmpArray.indexOf(posId), 1);
    } else {
      tmpArray.push(posId);
    }
    setPosFilter(tmpArray);
  };
  console.log("ReportData: ", reportData);
  return (
    <>
      <FullPageWrapper>
        <Modal
          isVisible={showStatsFilter}
          closeFn={setShowStatsFilter}
          direction="toTop"
        >
          {showStatsFilter ? (
            <View style={{ flex: 1 }}>
              <Text style={{ marginVertical: 10, fontWeight: "bold" }}>
                Välj period
              </Text>

              <Picker
                selectedValue={timeFilter || ""}
                onValueChange={(itemValue) => {
                  dataRange(itemValue);
                  setTimeFilter(itemValue);
                  if (itemValue === "select") {
                    setDisplayDateSelector(true);
                  }
                }}
              >
                <Picker.Item value={"week"} label="Den här veckan" />
                <Picker.Item value={"lastweek"} label="Förra veckan" />
                <Picker.Item value={"month"} label="Den här månaden" />
                <Picker.Item value={"lastmonth"} label="Förra månaden" />
                <Picker.Item value={"quarter"} label="Detta kvartal" />
                <Picker.Item value={"lastquarter"} label="Förra kvartalet" />
                <Picker.Item value={"year"} label="Detta året" />
                <Picker.Item value={"lastyear"} label="Förra året" />
                <Picker.Item value={"select"} label="Välj datum" />
              </Picker>

              {timeFilter === "select" && (
                <View
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Ange period
                  </Text>
                  <View
                    style={{
                      flexDirection: Platform.OS === "web" ? "column" : "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {Platform.OS === "web" ? (
                      <View style={{ zIndex: 1000 }}>
                        {displayDateSelector && (
                          <CalendarPicker
                            startFromMonday={true}
                            selectedDayColor="#00a13a"
                            allowRangeSelection={true}
                            previousTitle="Tidigare"
                            nextTitle="Nästa"
                            weekdays={[
                              "Mån",
                              "Tis",
                              "Ons",
                              "Tor",
                              "Fre",
                              "Lör",
                              "Sön",
                            ]}
                            months={[
                              "Jan",
                              "Feb",
                              "Mar",
                              "Apr",
                              "Maj",
                              "Jun",
                              "Jul",
                              "Aug",
                              "Sep",
                              "Okt",
                              "Nov",
                              "Dec",
                            ]}
                            // initialDate={moment().subtract("2", "weeks").toDate()}

                            onDateChange={(selection, type) => {
                              if (type === "START_DATE") {
                                setBeginDate(selection);
                              }
                              if (type === "END_DATE") {
                                setEndDate(selection);
                              }
                            }}
                          />
                        )}
                        {/* <SimplerDatePicker
                          minDate={moment().subtract(2, "years")}
                          maxDate={moment()}
                          onDatePicked={(date) => setBeginDate(date)}
                        /> */}
                        {/* <WebPicker /> */}
                        {/* <DatePicker
                          selected={beginDate.toDate()}
                          locale="sv"
                          onSelected={(date) => {
                            console.log("Selected date:", date);
                            setBeginDate(moment(date));
                          }}
                        /> */}
                        <Button
                          type="outline"
                          title={`Visa ${
                            displayDateSelector ? "gruppfilter" : "datumväljare"
                          }`}
                          onPress={() =>
                            setDisplayDateSelector((prev) => !prev)
                          }
                        />
                      </View>
                    ) : (
                      <>
                        <Button
                          onPress={() => setShowBeingDatePicker(true)}
                          title="Fråndatum"
                        />
                        {/* <DateTimePickerModal
                          isVisible={showBeingDatePicker}
                          mode="date"
                          onConfirm={(date) => {
                            setBeginDate(moment(date));
                          }}
                          onCancel={() => setShowBeingDatePicker(false)}
                        /> */}
                      </>
                    )}
                    {Platform.OS === "web" ? (
                      <></>
                    ) : (
                      <>
                        <Button
                          onPress={() => setShowBeingDatePicker(true)}
                          title="Fråndatum"
                        />
                        {/* <DateTimePickerModal
                          isVisible={showBeingDatePicker}
                          mode="date"
                          onConfirm={(date) => {
                            setBeginDate(moment(date));
                          }}
                          onCancel={() => setShowBeingDatePicker(false)}
                        /> */}
                      </>
                    )}
                  </View>
                </View>
              )}
              {!displayDateSelector && (
                <>
                  <Text style={{ marginVertical: 10, fontWeight: "bold" }}>
                    Välj grupp
                  </Text>
                  <ScrollView
                    contentContainerStyle={{ maxHeight: 300, zIndex: -1 }}
                  >
                    {organizationStore.organization.groups.map((group) => {
                      console.log(group);
                      return (
                        <View key={`filter-group-${group.id}`}>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginVertical: 10,
                            }}
                          >
                            <TouchableOpacity
                              onPress={() => toggleGroupFilter(group.id)}
                            >
                              <Icon name="tp-icon-plus" size={16} />
                            </TouchableOpacity>
                            {/* <Switch
                          value={groupFilter.includes(group.id)}
                          onValueChange={value => }
                        /> */}
                            <Text style={{ marginLeft: 20 }}>
                              {group.name || ""}
                            </Text>
                          </View>
                          {groupFilter.includes(group.id) && (
                            <View style={{ marginLeft: 30 }}>
                              {/* pos list */}
                              {group.pointOfSales &&
                                group.pointOfSales.length &&
                                group.pointOfSales.map((pos) => {
                                  return (
                                    <View
                                      key={`filter-pos-${pos.id}`}
                                      style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginVertical: 10,
                                      }}
                                    >
                                      <Switch
                                        value={posFilter.includes(pos.id)}
                                        onValueChange={(value) =>
                                          togglePosFilter(pos.id)
                                        }
                                        // value={groupFilter.filter()}
                                        // onValueChange={value => toggleGroupInFilter(value, group.id)}
                                      />
                                      <Text style={{ marginLeft: 20 }}>
                                        {pos.name}
                                      </Text>
                                    </View>
                                  );
                                })}
                            </View>
                          )}
                        </View>
                      );
                    })}
                  </ScrollView>
                </>
              )}
            </View>
          ) : (
            <></>
          )}
        </Modal>
        <Header>
          <BackButton to={APP_PATHS.organization.home} />
          <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
          <HeaderTitleSubtitle
            subtitle={
              organizationStore.organization
                ? organizationStore.organization.name
                : " "
            }
            title={`Rapporter`}
          />
          {/* <HeaderMenuButton /> */}
        </Header>
        <View
          style={{
            zIndex: -1,
            alignSelf: "stretch",
            // backgroundColor: theme.colors.primary,
            flexDirection: "row",
            marginTop: 10,
            paddingVertical: 15,
            paddingHorizontal: 10,
            // alignItems: "flex-end"
            justifyContent: "space-between",
          }}
        >
          <Button title={`FILTRERA`} onPress={() => setShowStatsFilter(true)} />
          <Button
            title={
              reportType === "sales"
                ? "Visa Produktstatistik"
                : "Visa Försäljningsstatistik"
            }
            type="outline"
            onPress={() => {
              setLoading(true);
              setReportType(reportType === "order" ? "sales" : "order");
            }}
          />
        </View>
        {/* Breakpoint */}
        {loading ? (
          <View
            style={{
              flex: 1,
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <FullPageActivityIndicator />
          </View>
        ) : (
          <ContentScroll>
            <View
              style={{
                alignSelf: "stretch",
                paddingVertical: 15,
              }}
            >
              <Text style={{ textAlign: "right" }}>
                Period: {moment(beginDate).format("DD/MM/YYYY")} -{" "}
                {moment(endDate).format("DD/MM/YYYY")}
              </Text>
              <Text style={{ textAlign: "right" }}>
                Kassor:{" "}
                {posFilter.length > 0
                  ? organizationStore._getPosNameListAsString(posFilter)
                  : "Alla kassor"}
              </Text>
              <Text style={{ marginVertical: 10, fontWeight: "bold" }}>
                Total försäljning under perioden
              </Text>
              <Text h4 style={{ marginBottom: 10 }}>
                {reportType === "sales"
                  ? reportData.total
                  : reportData.salesReport.total}{" "}
                kr
              </Text>
              {/* Graph */}
              {reportType === "sales" && reportData.total > 0 && (
                <View
                  style={{
                    marginVertical: 15,
                    // borderWidth: 1,
                    // borderColor: "#cdcdcd",
                  }}
                >
                  <Graph
                    beginDate={beginDate}
                    endDate={endDate}
                    report={reportData}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignSelf: "stretch",
                    }}
                  >
                    <Text style={{ fontSize: 11 }}>
                      {moment(reportData.firstDate).format("YYYY-MM-DD")}
                    </Text>
                    <Text style={{ fontSize: 11 }}>
                      {moment(reportData.lastDate).format("YYYY-MM-DD")}
                    </Text>
                  </View>
                </View>
              )}
              <Table>
                <Row
                  data={tableHead}
                  style={{ backgroundColor: "#cdcdcd", padding: 5 }}
                  textStyle={{ fontWeight: "bold" }}
                />
                <Rows data={tableData} style={{ padding: 5 }} />
              </Table>
            </View>
          </ContentScroll>
        )}
      </FullPageWrapper>
    </>
  );
};

export default OrganizationReportsScreen;

interface GraphProps {
  beginDate: Moment;
  endDate: Moment;
  report: any;
}
const Graph: React.FC<GraphProps> = ({ beginDate, endDate, report }) => {
  const numberOfDays = endDate.diff(beginDate, "days");

  let dataArray = [];
  let tmpTotal;
  let currentDate;

  console.log("GRAPH REPPRT: ", report);
  console.log("NumberOfDays: ", numberOfDays);

  report.entries.map((entry, idx) => {
    if (moment(entry.firstDate).format("YYYY-MM-DD") === currentDate) {
      dataArray.filter((da) => da.date === currentDate)[0].totalSales +=
        entry.totalSales;
      // tmpTotal += entry.totalSales;
      // console.log("TMPTOTAL:", tmpTotal);
      return;
    } else {
      currentDate = moment(entry.firstDate).format("YYYY-MM-DD");
      dataArray.push({
        date: currentDate,
        totalSales: entry.totalSales,
      });
      // tmpTotal = entry.totalSales;
      // console.log("TMPTOTAL:", tmpTotal);
      console.log("currentDate:", currentDate);
    }
  });

  console.log("dataArray: ", dataArray);
  const maxValue = Math.max.apply(
    Math,
    dataArray.map(function (o) {
      return o.totalSales;
    })
  );
  return (
    <View
      style={{
        height: 100,
        flex: 1,
        alignItems: "flex-end",
        // alignContent: "flex-end",
        justifyContent: "space-evenly",
        flexDirection: "row",
      }}
    >
      {dataArray.map((day, idx) => (
        <GraphBar
          height={(day.totalSales / maxValue) * 100}
          width={(1 / dataArray.length) * 100}
        />
      ))}
      {/* {Array(numberOfDays).forEach((_) => (
      ))} */}
    </View>
  );
};

interface GraphBarProps {
  width?: number;
  height: number;
}

const GraphBar: React.FC<GraphBarProps> = ({ width = 10, height }) => {
  return (
    <View
      style={{
        backgroundColor: theme.colors.primary,
        width: width ? `${width}%` : null,
        height: height,
        maxWidth: 30,
      }}
    />
  );
};
