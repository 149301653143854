import * as Font from "expo-font";
import React from "react";
import * as Sentry from "sentry-expo";
import Index from "./src/index";

Sentry.init({
  dsn: "https://8611d3b435244ba19b9a0cccc5f41511@bugs.getplinka.com/4",
  enableInExpoDevelopment: true,
  debug: true, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
});

export default class App extends React.Component {
  state = {
    fontLoaded: false,
  };

  async componentDidMount() {
    // console.log("STARTING FONT LOADING");
    // await Font.loadAsync({
    //   "custom-font-icon": require("./src/assets/fonts/custom-font-icon.ttf")
    // });
    const sucess = await Font.loadAsync({
      TeamPay: require("./src/assets/fonts/teampay.ttf"),
    });
    console.log(sucess);
    this.setState({ fontLoaded: true });
    // console.log("STARTING FONT LOADING");
  }

  render() {
    if (!this.state.fontLoaded) {
      return null;
    }
    return <Index />;
  }
}

// export default App;
