import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { Row, Rows, Table } from "react-native-table-component";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import { API_ULRS, APP_PATHS } from "../config";
import axiosInstance from "../lib/axios";
import { Order } from "../lib/types/interfaces/shopping/order";
import { RegisterSteps } from "../stores/RegisterStore";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const PurchaseHistoryScreen: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [returnRef, setReturnRef] = useState("");

  const { pointOfSaleStore, registerStore } = useContext(RootStoreContext);

  const renderOrderAction = (order) => {
    if (order.transactionDirection === "return") {
      if (order.returnReasonCode === "felslag") {
        return <Text>Felslag</Text>;
      }
      return <Text>Retur</Text>;
    }
    if (order.returnOrderId !== null) {
      console.log(order);
      return <Text>Återköpt</Text>;
    }
    return (
      <Button
        title=">"
        onPress={() => {
          setReturnRef(order.transactionReference);
          setShowReturnModal(true);
        }}
      />
    );
  };
  useEffect(() => {
    const getData = async () => {
      const reqUrl = `${API_ULRS.url}/order/${pointOfSaleStore.pointofSaleId}/orders/25`;
      axiosInstance.get(reqUrl).then((res) => {
        let initialTableData = [];
        console.log(res.data.data);
        if (res.data.data.length > 0) {
          res.data.data.map((order: Order) => {
            initialTableData.push([
              moment(order.created).format("HH:mm"), // tid
              order.amountPaied, // värde
              order.paymentMethod, // metod
              order.transactionReference, // ref
              renderOrderAction(order),
              // knapp
            ]);
          });
        }
        setTableData(initialTableData);
        setLoading(false);
      });
    };
    getData();
  }, []);
  if (loading) {
    return <FullPageActivityIndicator />;
  }
  return (
    <>
      <FullPageWrapper>
        <Header>
          <BackButton to={APP_PATHS.register.register} />
          <HeaderTitleSubtitle title="Köphistorik" subtitle="" />
          {/* <HeaderMenuButton /> */}
        </Header>
        <ContentFixed>
          <View
            style={{
              padding: 15,
            }}
          >
            <Text>
              För att göra ett direkt återköp, klicka på '&gt;' vid den aktuella
              transaktionen.
            </Text>
          </View>
        </ContentFixed>
        <ContentScroll>
          <Table>
            <Row
              style={styles.head}
              data={["Tid", "Kr", "Metod", "Ref", "Retur"]}
            />
            <Rows
              flexArr={[1, 0.8, 1, 1.2, 0.8]}
              style={styles.row}
              textStyle={styles.text}
              data={tableData}
            />
          </Table>
        </ContentScroll>
        <Modal
          isVisible={showReturnModal}
          closeFn={setShowReturnModal}
          direction="toTop"
        >
          <Formik
            initialValues={{
              code: "",
              message: "",
            }}
            onSubmit={(data, { setSubmitting }) => {
              // console.log("FORMIK DATA: ", data);

              registerStore._makeReturn(data, returnRef).then((res) => {
                console.log("THIS IS THE RESULT: ", res);
                setSubmitting(false);
                // registerStore.isReturn = true;
                // registerStore.transactionReference =
                //   res.data.transactionReference;
                // registerStore.totalSum = res.data.amountPaied;
                // registerStore.paymentMethod = { paymentMethodKey: "cash" };
                registerStore.currentStep = RegisterSteps.Return;
                history.push(APP_PATHS.register.register);
              });
              // if (typeof doneAction !== undefined) {
              //   // console.log("action called");
              //   setTimeout(() => {
              //     // console.log("Stäng!");
              //     doneAction(false);
              //   }, 300);
              // }
              // } else {
              //   // console.log("CRAP!");
              // }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <View>
                <Text h3>Gör ett återköp</Text>
                <Text>{returnRef}</Text>
                <View
                  style={{
                    paddingVertical: 15,
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Text style={{ fontWeight: "bold", paddingLeft: 15 }}>
                    Välj skäl:{" "}
                  </Text>
                  <Button
                    title="Retur"
                    onPress={() => {
                      setFieldValue("code", "retur");
                    }}
                    type={values.code === "retur" ? "solid" : "outline"}
                  />
                  <Button
                    title="Felslag"
                    onPress={() => {
                      setFieldValue("code", "felslag");
                    }}
                    type={values.code === "felslag" ? "solid" : "outline"}
                  />
                </View>
                <View style={{ paddingVertical: 15 }}>
                  <Input
                    label="Kommentar"
                    value={values.message}
                    onChangeText={handleChange("message")}
                    onBlur={handleBlur("message")}
                  />
                </View>

                <Button
                  title={isSubmitting ? "Gör återköp.." : "Gör återköp"}
                  onPress={() => handleSubmit()}
                  disabled={isSubmitting || !values.code}
                />
              </View>
            )}
          </Formik>
        </Modal>
      </FullPageWrapper>
      {/* {orders.map(o => (
        <Text>{o.id}</Text>
      ))} */}
    </>
  );
};

export default PurchaseHistoryScreen;

const styles = StyleSheet.create({
  container: { flex: 1, padding: 16, paddingTop: 30, backgroundColor: "#fff" },
  head: { height: 40, backgroundColor: "#f1f8ff" },
  text: { margin: 6 },
  row: {
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomColor: "#efefef",
    borderBottomWidth: 1,
  },
});
